<template>
		<div>
			<div
				class=center
				v-if="this.$root.investor.isMA==true"
			>
					<router-link to="/messages">
						Check Messages	
					</router-link>
			</div>
			<div 
				class="contact_cont"
				v-if="success==false"
		>
				<div class="full">
					<v-form 
						ref="form" 
						v-model="valid" 
						lazy-validation
					>
					<v-container>
						<v-row
							v-if="pageInfo.links"
						>
							<v-col md=2>
								<v-img
									:lazy-src="pageInfo.links[0].link"
								>
								</v-img>
							</v-col>
							<v-col md=10>
								<div class="text-h5">
									{{ category.name }}
								</div>
								<div class="text-h4">
									{{ plan.name }}
								</div>
								<div
									class="text-h3"
								>
									{{ this.pageInfo.title }}
								</div>
								<div
									class="text-h5"
								>
									{{ this.pageInfo.text }}
								</div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<a
											target="_blank"
											href="/plans/5/"
											@click.stop
											v-on="on"
										>
											More Information
										</a>
									</template>
									<div 
										v-html="plan.snippet"
									>
									</div>
								</v-tooltip>
							</v-col>
						</v-row>
						<v-row>
							<v-col>

					<div 
						class="text-h3"
					>
						Step 1: Products 
					</div>
					<v-container>
						<v-row align_content="end">
							<v-col md=3>
								<v-text-field
									label="Number of Licenses"
									:rules="[rules.required, rules.nonzero]"
									maxlength="16"
									v-model=nlicenses
									@keypress="numbersOnly($event);"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row align_content="end">
							<v-col md=3>
								<v-text-field
									label="Number of Mobile Printers"
									:rules="[]"
									maxlength="16"
									v-model=nprinters
									@keypress="numbersOnly($event);"
								>
								</v-text-field>
							</v-col>
							<v-col md=3>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<a
											target="_blank"
											href="/plans/6/"
											@click.stop
											v-on="on"
										>
											Mobile Printer Package @ ${{printercost}}/mo	
										</a>
									</template>
									Brother RuggedJet RJ4230BL: Mobile 4" DT Printer w/USB, Bluetooth/MFi, NFC Pairing.  <br>Includes:<br>
									<ul>
										<li>Li-Ion Battery</li>
										<li>Doc Set</li> 
										<li>Roll Holder Stop</li>
										<li>Belt Clip & ZPL/CPCL</li>
										<li>AC Adapter for RuggedJet</li>
									</ul>
									Also includes 6, 78’ printer rolls + shipping annually.
								</v-tooltip>
							</v-col>
						</v-row>
						<v-row>
							<v-col md=3>
								<v-select
									v-model=support_option
									:items="support_options"
									label="Support Options"
									@input="updatePrice()"
								>
								</v-select>
							</v-col>
							<v-col md=2>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<a
											target="_blank"
											href="/plans/6/"
											@click.stop
											v-on="on"
										>
											More Information
										</a>
									</template>
									Show Support Options
								</v-tooltip>
							</v-col>
						</v-row>
						<v-row>
							<v-col md=3>
								<div
									v-if="discount['per_user']"
								>
									${{ this.nprinters*this.printercost + this.nlicenses * (this.price["per_user"] + this.support_data[this.support_option]["per_user"] - this.discount["per_user"] )	}} Monthly
								</div>
								<div v-else>
									${{ this.nprinters*this.printercost + this.nlicenses * (this.price["per_user"] + this.support_data[this.support_option]["per_user"])	}} Monthly
								</div>
							</v-col>	
							<v-col md=5>
								<div v-if='this.nprinters>0'>
									<div v-if='this.support_data[this.support_option]["per_user"]>0'>
										({{ this.nlicenses }} Users @ ${{ this.price["per_user"] }} License + ${{ this.support_data[this.support_option]["per_user"]}} Support + {{this.nprinters}} Printers @ ${{this.printercost}} Per User)
									</div>
									<div v-else>
										({{ this.nlicenses }} Users @ ${{ this.price["per_user"] }}  + {{this.nprinters}} Printers  @ ${{this.printercost}} Per User)
									</div>
								</div>
								<div v-else>
									<div v-if='this.support_data[this.support_option]["per_user"]>0'>
										({{ this.nlicenses }} Users @ ${{ this.price["per_user"] }} License + ${{ this.support_data[this.support_option]["per_user"]}} Support Per User)
									</div>
									<div v-else>
										({{ this.nlicenses }} Users @ ${{ this.price["per_user"] }} Per User)
									</div>
								</div>
						
								<div
									v-if="discount['per_user']"
									class="red--text"
								>
									${{this.discount["per_user"]}} Monthly Discount
								</div>
							</v-col>
						</v-row>
						<v-row>
							<v-col md=3>
								<div
									v-if="discount['setup']"
								>
									${{ this.price["setup"] - discount['setup']}} Setup
								</div>
								<div
									v-else
								>
									${{ this.price["setup"] }} Setup
								</div>
							</v-col>
							<v-col md=2
								class="red--text"
								v-if="discount['setup']"
							>
									${{this.discount["setup"]}} Setup Discount
							</v-col>
						</v-row>
						<v-row>
							<v-col md=3>
								<v-text-field
									label="Discount Code"
									:rules="[rules.counter]"
									maxlength="30"
									v-model=discount_code
								>
								</v-text-field>
							</v-col>
							<v-col md=2>
								<v-btn
									outlined
									v-on:click='apply($event)'
								>
									Apply Code
								</v-btn>
							</v-col>
							<v-col>
									{{ this.code_status }}
							</v-col>
						</v-row>
						<v-row>
							<v-col md=3>
								<div class="text-h4">
									${{ 
										((1.0 - this.discount.percent/100.0) * (
											this.price["setup"] + 
											this.nprinters * 69 +
											this.nlicenses * (
												this.price["per_user"] + this.support_data[this.support_option]["per_user"] - this.discount.per_user
											) -
											this.discount.setup -
											this.discount.flat
										)).toFixed(2)
									}} Total 
									<br><br>
								</div>
							</v-col>
							<v-col>
							<div
								v-if="discount['percent']"
								class="red--text"
							>
								${{discount['percent']}}% Order Discount		
							</div>
							<div	
								v-if="discount['flat']"
								class="red--text"
							>
								${{discount['flat']}} Flat Order Discount		
							</v-col>
						</v-row>
					</v-container>	
					<div 
						class="text-h3"
					>
						Step 2:	Your Information
					</div>
					<v-text-field
						label="First Name"
						:rules="[rules.counter, rules.required]"
						counter
						maxlength="30"
						v-model=contact.first_name
					>
					</v-text-field>
					<v-text-field
						label="Last Name"
						:rules="[rules.counter, rules.required]"
						counter
						maxlength="30"
						v-model=contact.last_name
					>
					</v-text-field>
					<v-text-field
						label="Agency/Organization"
						v-model=contact.company
						counter
						maxlength="30"
						:rules="[rules.counter, rules.required]"
					>
					</v-text-field>
					<v-text-field
						label="e-mail"
						:rules="[rules.required, rules.email]"
						v-model=contact.email
					>
					</v-text-field>
					<v-text-field
						label="Phone"
						:rules="[rules.required, rules.phone]"
						v-model=contact.phone
					>
					</v-text-field>
					<v-text-field
						class="hp"
						label="Website"
						v-model=contact.website
					>
					</v-text-field>
					<br>
					<v-textarea
						height="100px"
						outlined
						counter="4096"
						name="Message"
						label="Message"
						:rules="rules.big_counter"
						v-model="contact.message"
					>
					</v-textarea>
					</v-form>
					<v-form 
						ref="ccard_form" 
						v-model="valid" 
						lazy-validation
					>
					<br><br>
					<div 
						class="text-h3"
					>
						Step 3:	Payment Information 
					</div>
					<v-text-field
						label="Name on Card"
						:rules="[rules.counter, rules.required]"
						counter
						maxlength="30"
						v-model=billing.cardname
					>
					</v-text-field>
					</v-col>
					</v-row>
					<v-row>
					<v-col md=11>
					<v-text-field
						label="Card Number"
						:rules="[rules.counter, rules.required]"
						counter
						maxlength="16"
						v-model=billing.cardnum
						@keypress="numbersOnly($event);setCardIcon()"
					>
					</v-text-field>
					</v-col>	
					<v-col md=1>
						<v-img
							width="50px"
							:src="cardInfo.url"
						>
						</v-img>
					</v-col>
					</v-row>
					<v-row>
					<v-col md="3">
					<v-select
						v-model=billing.expmo
						:items="months"	
						label="Exp Month"
					>
					</v-select>
					</v-col>
					<v-col md="3">
					<v-select
						v-model=billing.expyr
						:items="years"	
						label="Exp Year"
					>
					</v-select>
					</v-col>
					<v-col md=3>
					<v-text-field
						label="CVV"
						:rules="[rules.counter, rules.required]"
						counter
						maxlength="4"
						v-model=billing.cvv
						@keypress="numbersOnly($event)"
					>
					</v-text-field>
					</v-col>
					<v-col md=3>
					<v-text-field
						label="ZIP/Postal Code"
						:rules="[rules.counter, rules.required]"
						counter
						maxlength="10"
						v-model=billing.zip
					>
					</v-text-field>

					</v-col>
					</v-row>
					<v-row>
					<v-col>
					<div
						class="text-h3"
					>
						Step 4:	Terms and Conditions 
					</div>
					</v-col>
					</v-row>
					<v-row>
					<v-col>
					<v-checkbox
						v-model="agree"
					>
						<template v-slot:label>
							<div>
							I have read and agree to the
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<a
											target="_blank"
											href="/terms"
											@click.stop
											v-on="on"
										>
											terms of service
										</a>
									</template>
									Review Terms of Service
								</v-tooltip>
							.
							</div>
						</template>
					</v-checkbox>
					<v-alert
						v-model="alert"
						border="left"
						color="orange accent-2"
						dark
						v-if="agree"
					>
						Please double-check your order.	Once you press the "Purchase" button your card will be charged 
						${{ 
							((1.0 - this.discount.percent/100.0) * (
								this.price["setup"] + 
								this.nlicenses * (
									this.price["per_user"] + this.support_data[this.support_option]["per_user"] - this.discount.per_user
								) -
								this.discount.setup -
								this.discount.flat
							)).toFixed(2)
						}}.
					</v-alert>
					<v-alert
						v-model="alert"
						border="left"
						color="red accent-2"
						dark
						v-if="show_alert"
					>
						Please fill out all required fields before submitting.
					</v-alert>
					<v-alert
						v-model="alert"
						border="left"
						color="red accent-2"
						dark
						v-if="card_declined"
					>
						Your card was declined.	Please enter a different payment method, or <a href="/contact" target="_blank">contact us</a> for further assistance.
					</v-alert>
					</v-col>
					</v-row>
					</v-container>
					</v-form>
					<v-btn
						outlined
						v-on:click='purchase($event, "ccard")'
						:disabled="!agree"
					>
						Purchase
					</v-btn>
					&nbsp; &nbsp;
					<v-btn
						outlined
						v-on:click='purchase($event, "quote")'
						:disabled="!agree"
					>
						Download Quote 
					</v-btn>
					<br>
					<br>
				</div>	
			</div>
			<div v-else>
				<center>
					<h3>Your order has been successfully placed.	TBL Systems will contact you with your login credentials.</h3>
					<router-link to="/">
						Home
					</router-link>
					<br>
				</center>
			</div>
		</div>
</template>

<script>
module.exports = {
	data() {
		return {
			discount: {
				"sku":"",
				"per_user":0,
				"setup":0,
				"flat":0,
				"percent": 0.0
			},
			discount_code: "",
			code_status: "",
			show_alert: false,
			card_declined: false,
			support_option: 0,
			support_options: [],
			support_data: [{"per_user": 0}],
			plan: {},
			category: {},
			price: {},
			nlicenses: 1,
			nprinters: 0,
			printercost: 69,
			pageInfo: [],
			agree: false,
			cardInfo: {
				"type": "unknown",
				"url": "/img/bank-card-back-side.png"
			},
			unknownCard: {
				"type": "unknown",
				"url": "/img/bank-card-back-side.png"
			},
			cardOptions: {
				"3": { "type": "American Express", "url": "/img/amex.png"},
				"4": { "type": "Visa", "url": "/img/visa.png"},
				"5": { "type": "Mastercard", "url": "/img/mastercard.png"},
				"6": { "type": "Discover", "url": "/img/discover.png"}
			},
			years: [],
			months: [],
			what: "Unknown",
			success: false,
			billing: {
				"cardname": "",
				"cardnum": "",
				"expmo": "",
				"expyr": "",
				"cvv":"",
				"zip":""
			},
			contact: {
				"first_name": "",
				"last_name": "",
				"company": "",
				"email": "",
				"website": "",
				"regarding": "",
				"message": "",
				"phone": "",
				"user_id": -1
			},
			rules: {
				counter: value => value.length <= 30 || 'Max 30 characters',
				big_counter: value => value.length <= 4096 || 'Max 4096 characters',
				required: value => !!value || 'Required.',
				nonzero: value => parseInt(value)>0 || "Select Number of Licenses",
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				},
				phone: value => {
					const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
					return pattern.test(value) || 'Invalid phone number.'
				},
			},
			regarding: [ 
				"Sales",
				"Support",
				"Question",
				"Comment",
				"Nonprofit Status",
				"Integrations", 
				"Dedicated Instance",
				"Other"
			],
			titleFontStyle: {},
			titleFontStyleMobile: {},
			textFontStyle: {},
			textFontStyleMobile: {}, 
		};
	},
	methods: {
		apply(evt) {
			evt = (evt)?evt:window.event;

			if(this.discount_code.length==0) {
				this.code_status = "Please Enter Code";
				this.zero_discount()
				return;
			}

			this.$http.get("/svc/discount/" + this.discount_code + "/").then(response => {
				if(response.data) {
					if(response.data.discount) {
						console.log(response.data.discount.discount)
						var d = JSON.parse(response.data.discount.discount)
						console.log(d)

						this.discount.sku = "sku" in d?d.sku:"";
						this.discount.per_user = "per_user" in d?d.per_user:0
						this.discount.setup = "setup" in d?d.setup:0
						this.discount.flat = "flat" in d?d.flat:0
						this.discount.percent = "percent" in d?d.percent:0.0

						// if sku is part if discount code, make sure it matches
						if("sku" in d) {
							if(d["sku"]!=this.price["sku"] && d["sku"]!=this.support_data[this.support_option]["sku"]) {
								this.zero_discount()
								this.code_status = "This Discount Does Not Apply To Your Product"
								return;
							}
						}
						this.code_status = "Discount Has Been Applied"
					} else {		
						this.zero_discount()
						this.code_status = "Invalid Discount Code";
					}
				}
			})
		},

		zero_discount() {
			this.discount.sku = ""
			this.discount.per_user = 0
			this.discount.setup = 0
			this.discount.flat = 0
			this.discount.percent = 0.0
		}, 

		purchase(evt, payment) {
			evt = (evt)?evt:window.event;
			var o = {}

			o["order"] = {}
			o["order"]["charge"] = this.nprinters*this.printercost + this.price["setup"] + this.nlicenses * (this.price["per_user"] + this.support_data[this.support_option]["per_user"])
			o["order"]["recurring"] = this.nprinters*this.printercost + this.nlicenses * (this.price["per_user"] + this.support_data[this.support_option]["per_user"]) 
			o["order"]["support"] = this.support_data[this.support_option]
			o["order"]["discount"] = this.discount
			o["order"]["discount"]["code"] = this.discount_code
			o["order"]["discount"]["status"] = this.code_status

			
			this.price["licenses"] = this.nlicenses
			o["order"]["product"] = [this.price]	// "product" will be an array, because at some point we will want to support more than a single product.

			o["order"]["product"].push({ "setup": 0, "licenses": parseInt(this.nprinters), "per_user": parseInt(this.printercost), "sku": "PRNT01" })

			o["contact"] = this.contact

			if(payment=="ccard") {
				if(this.$refs.form.validate()&&this.$refs.ccard_form.validate()) {
					this.billing["method"] = "ccard"
					o["billing"] = this.billing
				} else {
					this.show_alert = true;
					return;
				}
			} else if(payment=="quote") {
				if(this.$refs.form.validate()) {
					o["billing"] = { "method": "quote" };
				} else {
					this.show_alert = true;
					return;
				}
			}

			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			//alert(JSON.stringify(o))	
			this.show_alert = false;

			var ep = "/svc/purchase/"
			this.$http.post(ep, o, { headers }).then(response => {
				if(response.data.code==800) {
					this.success=true;
				} else if(response.data.code==801) {
					this.card_declined = true;
				} else if(response.data.code==802) {
					var dl_url = "/svc/purchase/quote/" + response.data.guid + "/"
					const save = document.createElement('a');
					save.href = dl_url;
					save.target = '_blank';
					save.dispatchEvent(new MouseEvent('click'));
				//	window.location.assign(dl_url);
				} else if(response.data.code==601) {
					alert("Please fill in all required fields.");
				} else {
					alert("Unknown Error:	" + response.data.description);
				}
			})
		},
		setCardIcon() {
			var c = this.billing.cardnum.charAt(0);
			console.log("char is " + c);
			try {
				this.cardInfo.url = this.cardOptions[c].url
				this.cardInfo.type = this.cardOptions[c].type
			} catch(e) {
				this.cardInfo.type = this.unknownCard.type;
				this.cardInfo.url = this.unknownCard.url;
			}	
		},
		numbersOnly(evt) {
			evt = (evt)?evt:window.event;
			var charCode = (evt.which)?evt.which:evt.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				evt.preventDefault();
			} else {
				return true;
			}	
		},
		changed() {
		},
		getReadableDate(ts) {
			console.log(ts);
			var t = new Date(ts*1000);
			//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			var times = t.toLocaleDateString("en-US", options);
			console.log(times);
			return(times);
		},

		utf8_to_b64( str ) {
			return window.btoa(unescape(encodeURIComponent( str )));
		},

		b64_to_utf8( str ) {
			return decodeURIComponent(escape(window.atob( str )));
		}
	},
	mounted() {
		var month = ["January","February","March","April","May","June","July", "August","September","October","November","December"];
		for(var m in month) {
			this.months.push( {
				"text": (parseInt(m)+1) + " - " + month[m],
				"value": parseInt(m)+1,
				"disabled": false
			})
		}

		var thisYear = new Date().getFullYear()

		for(var i=0; i<10; i++) {
			this.years.push(thisYear+i)
		}

		// Get the support options

		this.$http.get("/svc/menu/6/").then(response => {
			var so = response.data.menuList;
			var i = 0
			this.support_options = []
			this.support_data = []
			for(var o in so) {
				var cost;

				try {
					cost = JSON.parse(so[o].snippet2)
				} catch(e) {
					continue;
				}

				this.support_data.push(cost)

				if("per_user" in cost) {
					var text = ""
					if(cost["per_user"]>0) {
						text = so[o].name + " - $" + cost["per_user"] + " per user"
					} else {
						text = so[o].name + " - Included" 
					}

					this.support_options.push( {
						"text": text, 
						"value": i++,
						"disabled": false
					})
				}
			}
			console.log(JSON.stringify(this.support_data))
		})

		if(this.$root.investor && this.$root.investor.loggedIn) {
			this.contact.first_name=this.$root.investor.FirstName
			this.contact.last_name=this.$root.investor.LastName
			this.contact.user_id=this.$root.investor.uid
			this.contact.company="TBL Systems, Inc"
			this.contact.email=this.$root.investor.Email
		}	

		// Get the blocks associated with this plan
		var p = this.$route.path.split("/");
		var plan = p[2]
		var route = p[1]
		this.$http.get("/svc/page/"+route+"/").then(response => {
			this.pageInfo = response.data.pageBlocks[0]
		});

		// And get the plan itself
		var d = {
			"link": this.$route.path
		}
		var ep = "/svc/menu/"

		// Get product info
		this.$http.post(ep, d, {}).then(response => {
			if(response.data.code==200) {
					if(response.data.menuList.length>0) {
						this.price = JSON.parse(response.data.menuList[0].snippet2)
						this.plan = response.data.menuList[0]

						// get category info
						this.$http.post(ep, {"id":this.plan.parent} , {}).then(response => {
							this.category = response.data.menuList[0]
						})
					}
			}
		})
		
	}
};
</script>

<style scoped>
.contact_cont {
	max-width: 1200px;
	height: 100%;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}

.full {
}

.left_cont {
	display: inline;
	width: 20%;
	float: left;
}
.right_cont {
	display: inline;
	width: 20%;
	float: left;
}
.main_cont {
	display: inline;
	width: 60%;
	float: left;
}
.primary_img {
	max-height: 200px;
	max-width: 700px;
	object-fit: cover;
}

.mobile {
	margin: 0px 10px 0px 10px;
}

.hp {
	display: none;
}

</style>


<style>
blockquote {
	padding: 0 1em;
	border-left: 3px solid rgba(0,0,0,.05);
	margin: 0 0 1.41575em 3em;
	font-style: italic;
}
hr {
	width:80%;
	text-align:left;
	margin-left:0;
	color: rgba(0,0,0,0.05);
}
ol, ul {
	margin: 0 0 1.41575em 3em;
	padding: 0;
}
</style>
